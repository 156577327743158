import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { autoinvestStrategyWasSet } from './actions';

function* resetAutoinvestStrategiesCache(): SagaIterator {
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.LatestAutoinvestStrategy);
}

function* watchAutoinvestStrategySetup(): SagaIterator {
  yield takeEvery(autoinvestStrategyWasSet, resetAutoinvestStrategiesCache);
}

export default function* root(): SagaIterator {
  yield all([fork(watchAutoinvestStrategySetup)]);
}
